<template>
  <div class="user-list">
    <normal-table
      ref="normalTable"
      :tableAction="tableAction"
      method="post"
      :url="url"
      :extra-query="{}"
      hasResetBtn
      :searchForm.sync="form"
      :page-size="200"
      :has-search-form="true"
      @select="select"
      @select-all="selectAll"
      @loaded="loaded"
    >
      <template slot="formItem">
        <el-form-item class="search-item" label="账户" >
          <el-input v-model="form.name" placeholder="账户"></el-input>
        </el-form-item>
          <el-form-item label="是否封禁">
              <el-select v-model="form.isBan" placeholder="状态" style="width:70px">
                  <el-option label="已封禁" value="ban"></el-option>
                  <el-option label="正常" value="not"></el-option>
              </el-select>
          </el-form-item>
	      <el-form-item label="是否回收">
		      <el-select v-model="form.status" placeholder="状态" style="width:70px">
			      <el-option label="已回收" value="delete"></el-option>
			      <el-option label="正常" value=""></el-option>
		      </el-select>
	      </el-form-item>
      </template>
      <div slot="otherContent">
          <el-popconfirm title="确定解封这些用户嘛？" @confirm='banBatch(selectList)' style="margin-right:20px">
            <el-button slot="reference" type="primary">批量封禁</el-button>
          </el-popconfirm>
          <el-popconfirm title="确定解封这些用户嘛？" @confirm='unBanBatch(selectList)'>
              <el-button slot="reference" type="primary">批量解封</el-button>
          </el-popconfirm>
          <el-button slot="reference" type="primary" @click='gotoAddUser()' style="margin-left: 20px">添加账户</el-button>
      </div>
    </normal-table>
  </div>
</template>

<script>
import NormalTable from "@/components/NormalTable/index.vue";

export default {
  data() {
    return {
      total: 100,
      form: {},
      url: "/saas/saas/getUserList",
      tableAction: {
          label: "操作",
          prop: "handle",
          align: "center",
          formatter: ({_id, status_show}) => {
              return  (
                  <div class="action">
                      <el-popconfirm title="确定封禁这个用户嘛？" v-show={status_show!=='禁用'} onConfirm={() => this.ban([_id])}>
                          <el-button slot="reference" type="primary">封禁</el-button>
                      </el-popconfirm>
                      <el-popconfirm title="确定解封这个用户嘛？" v-show={status_show==='禁用'} onConfirm={() => this.unBan([_id])}>
                          <el-button slot="reference" type="primary">解封</el-button>
                      </el-popconfirm>
                      <el-button slot="reference" type="primary" onClick={() => {this.editUser(_id);}}>编辑</el-button>
		                  
	                    <el-popconfirm title="确定删除这个用户嘛？" v-show={status_show!=='回收站'} onConfirm={() => this.delete([_id])}>
			                  <el-button slot="reference" type="primary">删除</el-button>
		                  </el-popconfirm>
		                  <el-popconfirm title="确定恢复这个用户嘛？" v-show={status_show==='回收站'} onConfirm={() => this.putback([_id])}>
			                  <el-button slot="reference" type="primary">恢复</el-button>
		                  </el-popconfirm>
                  </div>
              )
          }
      },
      selectList: []
    };
  },

  components: {
    NormalTable,
  },

  mounted() {},

  methods: {
    // 里面可以取到总数
    loaded(data) {
      // console.log(data)
    },
    select(selection) {
      this.selectList = selection
      console.log(selection)
    },
    selectAll(selection){
      this.selectList = selection
    },
      editUser(_id) {
          this.$router.push({ path: "/saas/editUser", query: { _id } }).then(() => {
              try {
                  let matchedRoute = this.$route.matched.find(route => route.path === '/saas/editUser');
                  matchedRoute.instances.default.initUserData()
              } catch (e) {
                  console.log(e)
              }
          });
      },
      gotoAddUser() {
          this.$router.push({ path: "/saas/addUser"} ).then(() => {
              try {
                  let matchedRoute = this.$route.matched.find(route => route.path === '/saas/addUser');
                  matchedRoute.instances.default.resetForm()
              } catch (e) {
                  console.log(e)
              }
          });
      },
    ban(_ids) {
        _ids = _ids.join(',')
        this.$http.post('/saas/saas/ban', {_ids: _ids}).then(res => {
            if (res.code === 0) {
                this.$message({
                    message: "成功",
                    type: "success",
                });
                this.$refs.normalTable.getList()
            }
        })
    },
    unBan(_ids) {
        _ids = _ids.join(',')
        this.$http.post('/saas/saas/unBan', {_ids: _ids}).then(res => {
            if (res.code === 0) {
                this.$message({
                    message: "成功",
                    type: "success",
                });
                this.$refs.normalTable.getList()
            }
        })
    },
	  delete(_ids) {
		  _ids = _ids.join(',')
		  this.$http.post('/saas/saas/delete', {_ids: _ids}).then(res => {
			  if (res.code === 0) {
				  this.$message({
					  message: "成功",
					  type: "success",
				  });
				  this.$refs.normalTable.getList()
			  }
		  })
	  },
	  putback(_ids) {
		  _ids = _ids.join(',')
		  this.$http.post('/saas/saas/putback', {_ids: _ids}).then(res => {
			  if (res.code === 0) {
				  this.$message({
					  message: "成功",
					  type: "success",
				  });
				  this.$refs.normalTable.getList()
			  }
		  })
	  },
    banBatch(list) {
        let _ids = []
        list.forEach(it => {
            _ids.push(it._id)
        })
        this.ban(_ids)
    },
      unBanBatch(list) {
          let _ids = []
          list.forEach(it => {
              _ids.push(it._id)
          })
          this.unBan(_ids)
      },
      resetForm() {
        this.form = {}
      },
  },
};
</script>
<style lang="scss" scoped>
.user-list {
  .total{
    font-size: 14px;
    color: #333;
    text-align: right;
    padding-right: 30px;
  }
	.action {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
}
</style>

<style>
.el-message-box {
    max-width: 100% !important;
}
</style>